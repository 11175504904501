import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useAuth0 } from "@auth0/auth0-react";
import { IsAdmin } from '../../utils/utils';


function MenuLink({ text, href }) {
   return (
      <MenuItem>
         <Typography textAlign="center">
            <Link href={href} color="inherit" underline="none">{text}</Link>
         </Typography>
      </MenuItem>
   );
};

function ResponsiveAppBar() {
   const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
   const isAdmin = IsAdmin(user);

   const handleSignUp = async () => {
      await loginWithRedirect({
         prompt: "login",
         screen_hint: "signup",
         appState: {
            returnTo: "/callback",
         },
      });
   };

   const handleLogin = async () => {
      await loginWithRedirect({
         prompt: "login",
         appState: {
            returnTo: "/",
         },
      });
   };

   const handleLogout = () => {
      logout({
         returnTo: window.location.origin,
      });
   };

   const [anchorElUser, setAnchorElUser] = React.useState(null);
   
   const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   return (
      <AppBar position="static">
         <Container maxWidth="xl">
            <Toolbar disableGutters>
               <IconButton href="/">
                  <RestaurantIcon sx={{ color: "white", mr: { xs: 1, md: 3 } }} />
               </IconButton>
               <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                     mr: 2,
                     flexGrow: 1,
                     fontFamily: 'monospace',
                     fontWeight: { xs: 300, md: 700 },
                     letterSpacing: { md: '.3rem' },
                     color: 'inherit',
                     textDecoration: 'none',
                  }}
               >
                  Calorie Intake Log
               </Typography>
               <Box sx={{ flexGrow: 0 }}>
                  <Tooltip>
                     <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <MenuIcon fontSize="large" sx={{ color: "white" }} />
                     </IconButton>
                  </Tooltip>
                  <Menu
                     sx={{ mt: '45px' }}
                     id="menu-appbar"
                     anchorEl={anchorElUser}
                     anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                     }}
                     open={Boolean(anchorElUser)}
                     onClose={handleCloseUserMenu}
                  >
                     {!isAuthenticated &&
                        (<MenuItem onClick={handleLogin}>
                           <Typography textAlign="center">Login</Typography>
                        </MenuItem>)}
                     {!isAuthenticated &&
                        (<MenuItem onClick={handleSignUp}>
                           <Typography textAlign="center">Sign Up</Typography>
                        </MenuItem>)}
                     {isAuthenticated &&
                        (<MenuLink text={"Home"} href={"/"} />)}
                     {isAuthenticated && isAdmin &&
                        (<MenuLink text={"Admin"} href={"/admin"} />)}
                     {isAuthenticated &&
                        (<MenuLink text={"Profile"} href={"/profile"} />)}
                     {isAuthenticated &&
                        (<MenuItem onClick={handleLogout}>
                           <Typography textAlign="center">Logout</Typography>
                        </MenuItem>)}
                  </Menu>
               </Box>
            </Toolbar>
         </Container>
      </AppBar>
   );
}

export default ResponsiveAppBar;
