import React from "react";
import { PageLayout } from "../components/page-layout";
import Typography from '@mui/material/Typography';

export const NotFoundPage = () => {
   return (
      <PageLayout>
         <Typography variant="h5">
            Page not found.
         </Typography>
      </PageLayout>
   );
};
