import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import {
   getUserAsync,
   updateUserOptionsAsync,
} from '../services/postgres-pool';
import { PageLayout } from "../components/page-layout";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatabaseError } from "../components/database-error";

export const ProfilePage = () => {
   const { user } = useAuth0();

   const [userRecord, setUserRecord] = useState({});

   // Set these to empty strings, since react doesn't like initial values as null.
   // The component will handle the "number" type but we need to check for empty strings for snackbar validation in updateUser().
   const [formUserGoal, setFormUserGoal] = useState("");
   const [formUserAge, setFormUserAge] = useState("");
   const [formUserWeight, setFormUserWeight] = useState("");
   const [formUserHeight, setFormUserHeight] = useState("");
   const [formUserGender, setFormUserGender] = useState("");

   const [databaseResult, setDatabaseResult] = useState({});
   const [snackBarOpen, setSnackbarOpen] = useState(false);
   const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("");

   if (!user) {
      return null;
   }

   useEffect(() => {
      getUserAsync(user.sub)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setFormUserGoal(result.userRecord.user_goal);
               setFormUserAge(result.userRecord.user_age);
               setFormUserWeight(result.userRecord.user_weight);
               setFormUserHeight(result.userRecord.user_height);
               setFormUserGender(result.userRecord.user_gender);

               setUserRecord(result.userRecord);
            }
         });
   }, []);

   const hasBmrComponents = () => {
      return userRecord.user_weight
         && userRecord.user_height
         && userRecord.user_age
         && userRecord.user_gender
   };

   const calculatedBmr = () => {
      // Mifflin - St Jeor Equation (W = kilograms, H = cm, A = age)
      // For male: BMR = 10W + 6.25H - 5A + 5
      // For female: BMR = 10W + 6.25H - 5A - 161

      const weightKilograms = userRecord.user_weight / 2.205;
      const heightCentimeters = userRecord.user_height * 2.54;

      const bmr = (10 * weightKilograms)
         + (6.25 * heightCentimeters)
         - (5 * userRecord.user_age)
         + (userRecord.user_gender == "F" ? -161 : 5);

      return Math.round(bmr);
   };

   const updateUser = (columnName) => {
      setSnackbarOpen(false);
      setSnackBarErrorMessage("");

      let newValue;
      switch (columnName) {
         case "user_goal":
            newValue = formUserGoal;
            break;
         case "user_age":
            newValue = formUserAge;
            break;
         case "user_weight":
            newValue = formUserWeight;
            break;
         case "user_height":
            newValue = formUserHeight;
            break;
         case "user_gender":
            newValue = formUserGender;
            break;
         default:
            break;
      }

      if (columnName != "user_gender") {
         newValue = parseInt(newValue);

         if (Number.isNaN(newValue) || newValue <= 0) {
            setSnackBarErrorMessage("Please enter a positive number");
            setSnackbarOpen(true);
            return;
         }
		}

      updateUserOptionsAsync(userRecord.user_id, columnName, newValue)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setSnackBarErrorMessage("");
               setUserRecord(result.userRecord);
            }
            return result;
         })
         .then((result) => {
            if (result.success) {
               setSnackbarOpen(true);
            }
         });
   };

   const handleSnackbarClose = () => {
      setSnackbarOpen(false);
   };

   return (
      <PageLayout>
         <DatabaseError databaseResult={databaseResult} />

         <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
               <Card>
                  <CardHeader
                     title="Set Profile Options"
                     action={
                        <Snackbar
                           open={snackBarOpen}
                           autoHideDuration={2000}
                           onClose={handleSnackbarClose}
                           sx={{ position: "relative", top: 0, left: "0 !important", }}
                        >
                           <Alert
                              severity={snackBarErrorMessage ? "error" : "success"}
                              variant="filled"
                              sx={{ width: '100%', paddingTop: 0, paddingBottom: 0, }}
                           >
                              {snackBarErrorMessage || "Saved"}
                           </Alert>
                        </Snackbar>
                     }
                  />
                  <CardContent>
                     <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mb={2}>
                        <Grid item xs={5}>
                           <TextField
                              id="formUserGoal"
                              label="Daily goal"
                              type="number"
                              InputLabelProps={{
                                 shrink: true,
                              }}
                              value={formUserGoal}
                              onChange={(e) => { setFormUserGoal(e.target.value) }}
                           />
                        </Grid>
                        <Grid item>
                           <Button variant="outlined" startIcon={<SaveIcon />} onClick={() => updateUser("user_goal")}>
                              Save
                           </Button>
                        </Grid>
                     </Grid>
                        
                     <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mb={2}>
                        <Grid item xs={5}>
                           <TextField
                              id="formUserAge"
                              label="Age"
                              type="number"
                              InputLabelProps={{
                                 shrink: true,
                              }}
                              value={formUserAge || ""}
                              onChange={(e) => { setFormUserAge(e.target.value) }}
                           />
                        </Grid>
                        <Grid item>
                           <Button variant="outlined" startIcon={<SaveIcon />} onClick={() => updateUser("user_age")}>
                              Save
                           </Button>
                        </Grid>
                     </Grid>
                        
                     <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mb={2}>
                        <Grid item xs={5}>
                           <TextField
                              id="formUserWeight"
                              label="Weight (lbs.)"
                              type="number"
                              InputLabelProps={{
                                 shrink: true,
                              }}
                              value={formUserWeight || ""}
                              onChange={(e) => { setFormUserWeight(e.target.value) }}
                           />
                        </Grid>
                        <Grid item>
                           <Button variant="outlined" startIcon={<SaveIcon />} onClick={() => updateUser("user_weight")}>
                              Save
                           </Button>
                        </Grid>
                     </Grid>
                        
                     <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mb={2}>
                        <Grid item xs={5}>
                           <TextField
                              id="formUserHeight"
                              label="Height (inches)"
                              type="number"
                              InputLabelProps={{
                                 shrink: true,
                              }}
                              value={formUserHeight || ""}
                              onChange={(e) => { setFormUserHeight(e.target.value) }}
                           />
                        </Grid>
                        <Grid item>
                           <Button variant="outlined" startIcon={<SaveIcon />} onClick={() => updateUser("user_height")}>
                              Save
                           </Button>
                        </Grid>
                     </Grid>

                     <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={5}>
                           <FormControl fullWidth>
                              <InputLabel id="formUserGender_label">Gender</InputLabel>
                              <Select
                                 labelId="formUserGender_label"
                                 id="formUserGender"
                                 value={formUserGender || ""}
                                 label="Gender"
                                 onChange={(e) => { setFormUserGender(e.target.value) }}>
                                 <MenuItem value="F">Female</MenuItem>
                                 <MenuItem value="M">Male</MenuItem>
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item>
                           <Button variant="outlined" startIcon={<SaveIcon />} onClick={() => updateUser("user_gender")}>
                              Save
                           </Button>
                        </Grid>
                     </Grid>
                  </CardContent>
               </Card>
            </Grid>

            <Grid item xs={12} lg={6}>
               <Card>
                  <CardHeader
                     title="Current Options"
                     action={
                        hasBmrComponents() &&
                        <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                           Basal Metabolic Rate: <b>{calculatedBmr()}</b>
                           <br />
                           (Recommended Daily Goal)
                        </Typography>
                     }/>
                  <CardContent>
                     {userRecord.user_goal &&
                        <Box mb={2}><b>Daily Goal:</b> {userRecord.user_goal} calories</Box>}
                     {userRecord.user_age &&
                        <Box mb={2}><b>Age:</b> {userRecord.user_age}</Box>}
                     {userRecord.user_weight &&
                        <Box mb={2}><b>Weight:</b> {userRecord.user_weight} lbs.</Box>}
                     {userRecord.user_height &&
                        <Box mb={2}><b>Height:</b> {userRecord.user_height} inches</Box>}
                     {userRecord.user_gender &&
                        <Box><b>Gender:</b> {userRecord.user_gender == "F" ? "Female" : "Male"}</Box>}
                  </CardContent>
               </Card>
            </Grid>

            {process.env.REACT_APP_DEBUG_MODE == "true" &&
               <Grid item xs={12} lg={6}>
                  <Card>
                     <CardHeader title="Auth0 Token" />
                     <CardContent>
                        <pre>{JSON.stringify(user, null, 2)}</pre>
                     </CardContent>
                  </Card>
               </Grid>}
         </Grid>
      </PageLayout>
   );
};
