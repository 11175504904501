import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { AdminPage } from "./pages/admin-page";
import { CssBaseline } from "@mui/material";

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
       <PageLoader />
    );
  }

   return (
      <div>
         <CssBaseline />
         <Routes>
            <Route
               path="/"
               element={<AuthenticationGuard component={HomePage} />}
            />
            <Route
               path="/profile"
               element={<AuthenticationGuard component={ProfilePage} />}
            />
            <Route
               path="/admin"
               element={<AuthenticationGuard component={AdminPage} />}
            />
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="*" element={<NotFoundPage />} />
         </Routes>
      </div>
  );
};
