import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import {
   insertUserAsync,
} from '../services/postgres-pool';
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../components/page-layout";
import { DatabaseError } from "../components/database-error";

export const CallbackPage = () => {
   const { error, user } = useAuth0();
   const navigate = useNavigate();
   const [databaseResult, setDatabaseResult] = useState({});
   const approvedEmails = process.env.REACT_APP_APPROVED_EMAILS.split(',');
   const isApprovedUser = user && user.email && approvedEmails.includes(user.email);

   useEffect(() => {
      if (!error && isApprovedUser) {

         // TODO:: update this to insertOrUpdateUserAsync, where if user already registered, updated auth0_updated_at
         // TODO:: make sure this doesn't get called constantly because of the callback on every page navigate

         insertUserAsync(user)
            .then(result => {
               if (result.success) {
                  navigate("/");
               }
               setDatabaseResult(result);
            });
      }
   });

   if (error) {
      return (
         <PageLayout>
            Error: {error.message}
         </PageLayout>
      );
   }

   return (
      <PageLayout>
         <DatabaseError databaseResult={databaseResult} />

         {!databaseErrorMessage.errorMessage &&
            <div>
               Authenticated, redirecting to homepage...
            </div>}
      </PageLayout>
   );
};
