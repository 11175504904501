import React from "react";
import Container from '@mui/material/Container';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from './navigation/responsive-app-bar';

export const PageLayout = ({ children }) => {
   return (
      <Container sx={{ p: 1 }}>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container>
               <Grid item sx={{ mb: 2 }} xs={12}>
                  <ResponsiveAppBar />
               </Grid>
               <Grid item xs={12}>
                  {children}
               </Grid>
            </Grid>
         </LocalizationProvider>
      </Container>
  );
};
