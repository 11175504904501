import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

export const DailyGoal = ({ datePicked, rows, userGoal }) => {
	if (!userGoal) {
		return;
	}

	const totalCalories = rows.length > 0
		? rows.reduce((n, { item_calories, item_count }) => n + item_calories * item_count, 0)
		: 0;

	const maxCalories = Math.max(userGoal, totalCalories);
	const angleValue = totalCalories / maxCalories * 100;

	const rawAngleValue = totalCalories / userGoal * 100;
	const redThreshold = 100 + 100 / 3;
	const green = "#0F9D58";
	const yellow = "#F4B400";
	const red = "#DB4437";
	const arcFillColor = rawAngleValue > redThreshold
		? red
		: rawAngleValue > 100
			? yellow
			: green;

	return (
		<Paper>
			<Card>
				<CardHeader title="Daily Goal" subheader={datePicked.format('dddd, MMMM D, YYYY')} />
				<CardContent>
					<Grid container justifyContent="center">
						<Grid item>
							<Gauge
								value={angleValue}
								startAngle={-110}
								endAngle={110}
								text={`${totalCalories} / ${userGoal}`}
								width={400}
								height={300}
								sx={{
									[`& .${gaugeClasses.valueText}`]: {
										fontSize: 40,
										transform: 'translate(0px, 0px)',
									},
									[`& .${gaugeClasses.valueArc}`]: {
										fill: `${arcFillColor}`,
									},
								}}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Paper >
	);
};
