import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { adminGetUsersAndDateTotals } from '../services/postgres-pool';
import { AdminUserTotals } from "../components/admin-user-totals";
import { DatabaseError } from "../components/database-error";
import { IsAdmin } from '../utils/utils';

export const AdminPage = () => {
   const { user } = useAuth0();
   const navigate = useNavigate();
   const [databaseResult, setDatabaseResult] = useState({});
   const isAdmin = IsAdmin(user);

   useEffect(() => {
      if (!isAdmin) {
         navigate("/profile");
      }
      else {
         adminGetUsersAndDateTotals()
            .then(result => {
               setDatabaseResult(result);
               if (result.success) {
                  // TODO:: useState to put into a datagrid with expandable nested rows
               }
            });
      }
   }, []);

   return (
      <PageLayout>
         <DatabaseError databaseResult={databaseResult} />
         <AdminUserTotals data={databaseResult.data} />
      </PageLayout>
   )
};
