import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { DatabaseError } from "../components/database-error";
import { EnterCalories } from "../components/enter-calories";
import { WeightTracker } from '../components/weight-tracker';
import { getUserAsync } from '../services/postgres-pool';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

export const HomePage = () => {
   const { isAuthenticated, user } = useAuth0();

   const [userRecord, setUserRecord] = useState({});
   const [databaseResult, setDatabaseResult] = useState({});

   const approvedEmails = process.env.REACT_APP_APPROVED_EMAILS.split(',');
   const isApprovedUser = user && user.email && approvedEmails.includes(user.email);

   useEffect(() => {
      if (isAuthenticated && isApprovedUser && user.sub) {
         getUserAsync(user.sub)
            .then(result => {
               setDatabaseResult(result);
               if (result.success) {
                  setUserRecord(result.userRecord);
               }
            });
      }
   }, []);

   return (
      <PageLayout>
         <Grid container spacing={2}>
            {process.env.REACT_APP_DEBUG_MODE == "true" &&
               <Grid item xs={12}>
                  <Card>
                     <CardHeader title="Auth0 Token" />
                     <CardContent>
                        <pre>{JSON.stringify(user, null, 2)}</pre>
                     </CardContent>
                  </Card>
               </Grid>}

            <Grid item xs={12}>
               <DatabaseError databaseResult={databaseResult} />

               {Object.keys(userRecord).length != 0 &&
                  <EnterCalories userRecord={userRecord} />
               }

               {isAuthenticated && !isApprovedUser &&
                  <Box m={6}>
                     You have successfully registered, but are not an approved user.
                     <br />
                     Please contact an administrator to enable usage of this app.
                  </Box>
               }

               {!isAuthenticated &&
                  <Box m={6}>
                     To login or sign up, please use the menu at the top right.
                  </Box>
               }
            </Grid>

            <Grid item xs={12}>
               {Object.keys(userRecord).length != 0 &&
                  <WeightTracker userRecord={userRecord} />
               }
            </Grid>
         </Grid>
      </PageLayout>
   )
};
