import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
   {
      field: 'id',
      headerName: 'ID',
      type: 'number',
   },
   {
      field: 'auth0_email',
      headerName: 'Email',
   },
   {
      field: 'user_goal',
      headerName: 'Goal',
      type: 'number',
   },
   {
      field: 'auth0_email_verified',
      headerName: 'Verified',
      type: 'boolean',
   },
   {
      field: 'timezone',
      headerName: 'Time Zone',
   },
   {
      field: 'auth0_updated_at',
      headerName: 'Updated',
      type: 'datetime',
   },
];

export const AdminUserTotals = ({ data }) => {
   const [userId, setUserId] = useState(0);

   const handleUserClick = () => {

   };

   //console.log(data);

	if (!data) {
      return null;
	}

   return (
      <Grid container spacing={2}>
         <Grid item xs={9}>
            <Card>
               <CardHeader title="Users" />
               <CardContent>
                  <DataGrid
                     rows={data}
                     columns={columns}
                     initialState={{
                        pagination: {
                           paginationModel: {
                              pageSize: 5,
                           },
                        },
                     }}
                     pageSizeOptions={[5]}
                  />
               </CardContent>
            </Card>
         </Grid>
         <Grid item xs={3}>
            <Card>
               <CardHeader title="User Totals" subheader="email" />
               <CardContent>
                  component contents
               </CardContent>
            </Card>
         </Grid>
      </Grid>
   );
};
