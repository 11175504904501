import React, { useState, useEffect } from "react";
import {
   getUserWeightByDateAsync,
   getUserWeightsByDatesAsync,
   insertUserWeightAsync,
} from '../services/postgres-pool';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { DatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { LineChart } from '@mui/x-charts/LineChart';
import * as dayjs from 'dayjs';
import { DatabaseError } from "../components/database-error";

const green = "#0F9D58";
const yellow = "#F4B400";
const red = "#DB4437";
const rangeOptions = [
   { id: 0, text: "1 Week" },
   { id: 1, text: "2 Weeks" },
   { id: 2, text: "1 Month" },
   { id: 3, text: "3 Months" },
   { id: 4, text: "6 Months" },
];

function getXAxisLabel(labelDate) {
   // Only return label if it's exactly midnight.
   const obj = dayjs(labelDate);
   return obj.get('hour') == 0 ? obj.format("M/D") : "";
}

export const WeightTracker = ({ userRecord }) => {
   const { user_goal: userGoal } = userRecord;

   const yellowThreshold = userGoal + 1;
   const redThreshold = userGoal + userGoal / 3;

   const [data, setData] = useState([]);
   const [datePicked, setDatePicked] = useState(dayjs());
   const [dateRange, setDateRange] = useState(
      {
         startDate: dayjs().startOf("day").subtract(7, 'day'),
         endDate: dayjs().startOf("day").subtract(1, 'day'),
      });
   const [formWeight, setFormWeight] = useState("");
   const [rangeOption, setRangeOption] = useState(0);
   const [snackBarOpen, setSnackbarOpen] = useState(false);
   const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("");
   const [databaseResult, setDatabaseResult] = useState({});

   useEffect(() => {
      getUserWeightsByDatesAsync(userRecord.user_id, dateRange)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setData(result.rows);
            }
         })
         .then(() => {
            getUserWeightByDateAsync(userRecord.user_id, datePicked)
               .then(result => {
                  setDatabaseResult(databaseResult.errorMessage ? databaseResult : result);
						if (result.rows.length) {
                     setFormWeight(parseFloat(result.rows[0].weight_pounds));
						}
               });
         });
   }, []);

   const handleDateRangeChange = (rangeOptionId) => {
      const todaysDate = dayjs().startOf("day");

      let startDate;
      switch (rangeOptionId) {
         case 1:
            startDate = todaysDate.subtract(14, 'day');
            break;
         case 2:
            startDate = todaysDate.subtract(1, 'month');
            break;
         case 3:
            startDate = todaysDate.subtract(3, 'month');
            break;
         case 4:
            startDate = todaysDate.subtract(6, 'month');
            break;
         default:
            startDate = todaysDate.subtract(7, 'day');
            break;
      }

      const updatedDateRange = {
         startDate: startDate,
         endDate: todaysDate.subtract(1, 'day'),
      };

      getUserWeightsByDatesAsync(userRecord.user_id, updatedDateRange)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setRangeOption(rangeOptionId);
               setDateRange(updatedDateRange);
               setData(result.rows);
            }
         });
   }

   const changeDate = (newDate) => {
      getUserWeightByDateAsync(userRecord.user_id, newDate)
         .then(result => {
            setDatabaseResult(result);
            setDatePicked(newDate);
            setFormWeight(result.rows.length ? parseFloat(result.rows[0].weight_pounds) : "");
         });
   };

   const updateWeight = () => {
      setSnackBarErrorMessage("");
      setSnackbarOpen(false);

      if (Number.isNaN(formWeight) || !formWeight || formWeight < 0) {
         setSnackBarErrorMessage("Weight must be a number");
         setSnackbarOpen(true);
         return;
		}

      insertUserWeightAsync(userRecord.user_id, datePicked, formWeight)
         .then(result => {
            setDatabaseResult(result);
				if (!result.success) {
               setSnackBarErrorMessage(result.errorMessage);
				}
            setSnackbarOpen(true);
         });
   };

   const handleSnackbarClose = () => {
      setSnackbarOpen(false);
   };

   return (
      <Paper>
         <Card>
            <CardHeader
               title="Weight Tracker"
               subheader={
                  <div>
                     {dateRange.startDate.format('ddd, MMMM D, YYYY')}
                     - {dateRange.endDate.format('ddd, MMMM D, YYYY')}
                  </div>
               }
               action={
                  <TextField
                     select
                     sx={{ minWidth: 150 }}
                     label="Date Range"
                     value={rangeOption}
                     onChange={(event) => handleDateRangeChange(event.target.value)}
                  >
                     {rangeOptions.map(r =>
                        <MenuItem key={r.id} value={r.id}>{r.text}</MenuItem>
                     )}
                  </TextField>
               }
            />
            <CardContent>
               <DatabaseError databaseResult={databaseResult} />

               <Grid container justifyContent="center">
                  <Grid item xs={4}>
                     <Card>
                        <CardHeader
                           title="Enter Weight"
                           action={
                              <Snackbar
                                 open={snackBarOpen}
                                 autoHideDuration={2000}
                                 onClose={handleSnackbarClose}
                                 sx={{ position: "relative", top: 0, left: "0 !important", }}
                              >
                                 <Alert
                                    severity={snackBarErrorMessage ? "error" : "success"}
                                    variant="filled"
                                    sx={{ width: '100%', paddingTop: 0, paddingBottom: 0, }}
                                 >
                                    {snackBarErrorMessage ? "Error" : "Saved"}
                                 </Alert>
                              </Snackbar>
                           }
                        />
                        <CardContent>
                           <Grid container spacing={2}>
                              <Grid item xs={12}>
                                 <Paper sx={{ display: "inline-block" }}>
                                    <DatePicker
                                       id="datePicked"
                                       label="Date"
                                       value={datePicked}
                                       onChange={(newDate) => changeDate(newDate)}
                                    />
                                 </Paper>
                              </Grid>

                              <Grid item xs={12}>
                                 <Grid container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                    mb={2}>
                                    <Grid item xs={5}>
                                       <TextField
                                          id="formWeight"
                                          label="Weight"
                                          type="number"
                                          InputLabelProps={{
                                             shrink: true,
                                          }}
                                          value={formWeight || ""}
                                          onChange={(e) => { setFormWeight(e.target.value) }}
                                       />
                                    </Grid>
                                    <Grid item>
                                       <Button variant="outlined" startIcon={<SaveIcon />} onClick={() => updateWeight()}>
                                          Save
                                       </Button>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </CardContent>
                     </Card>
                  </Grid>
                  <Grid item width="100%" xs={8}>
                     {!databaseResult.errorMessage &&
                        (
                        <LineChart
                           margin={{ top: 10, }}
                           height={300}
                           sx={{ width: '100%' }}
                           grid={{ horizontal: true }}
                           series={[
                              {
                                 data: data.map(r => r.weight_pounds),
                                 valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
                              },
                           ]}
                           yAxis={[
                              {
                                 min: 0,
                                 colorMap:
                                 {
                                    type: 'piecewise',
                                    thresholds: [yellowThreshold, redThreshold],
                                    colors: [green, yellow, red],
                                 },
                              },
                           ]}
                           xAxis={[
                              {
                                 scaleType: 'time',
                                 min: dateRange.startDate.toDate(),
                                 max: dateRange.endDate.toDate(),
                                 data: data.map(r => r.weight_date),
                                 valueFormatter: (value) => getXAxisLabel(value),
                              },
                           ]}>
                        </LineChart>
                        )}
                  </Grid>
               </Grid>
            </CardContent>
         </Card>
      </Paper >
   );
};
