import React from "react";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

export const DatabaseError = ({ databaseResult }) => {
	const { errorMessage, method } = databaseResult;

	if (!errorMessage) {
		return null;
	}

	return (
		<Alert m={2} severity="error">
			<Box fontWeight="fontWeightMedium" display="inline">Database error in method "{method}": </Box>
			{errorMessage}
		</Alert>
	);
};
