'use server';
import { createPool } from '@vercel/postgres';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const pool = createPool({
   connectionString: process.env.REACT_APP_POSTGRES_URL
});

export async function insertUserAsync(user) {
   const { sub, email, email_verified, picture, nickname, updated_at, } = user;
   const timezone = dayjs.tz.guess();

   try {
      await pool.sql`INSERT INTO public.users
         (
            timezone,
            auth0_sub,
            auth0_email,
            auth0_email_verified,
            auth0_picture,
            auth0_nickname,
            auth0_updated_at,
            user_goal
         )
         SELECT 
            ${timezone},
            ${sub},
            ${email},
            ${email_verified},
            ${picture},
            ${nickname},
            ${updated_at},
            1500
         WHERE NOT EXISTS (SELECT * FROM public.users WHERE auth0_sub = ${sub})`;
      return { success: true };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "insertUserAsync" };
   }
};

export async function getUserAsync(userSub) {
	try {
      const { rows } = await pool.sql`SELECT * FROM users WHERE auth0_sub = ${userSub}`;

      return rows.length == 1
         ? { success: true, userRecord: rows[0], }
         : { success: false, errorMessage: "User not found", method: "getUserAsync" };
	} catch (e) {
      return { success: false, errorMessage: e.message, method: "getUserAsync" };
	}
}

export async function getUserItemsAsync(userId, datePicked) {
   const startDate = datePicked.format('YYYY-MM-DD');
   const endDate = datePicked.add(1, 'day').format('YYYY-MM-DD');

	try {
      const { rows } = await pool.sql`SELECT * FROM public.items
	      WHERE user_id = ${userId}
         AND item_date >= ${startDate}
         AND item_date < ${endDate}
         ORDER BY item_id`;

      return { success: true, rows: rows };
	} catch (e) {
      return { success: false, errorMessage: e.message, method: "getUserItemsAsync" };
	}
};

export async function getUserItemsByDatesAsync(userId, { startDate, endDate }) {
   const sqlStartDate = startDate.format('YYYY-MM-DD');
   const sqlEndDate = endDate.format('YYYY-MM-DD');

   try {
      const { rows } = await pool.sql`SELECT item_id, item_calories, item_count, item_date FROM public.items
	      WHERE user_id = ${userId}
         AND item_date >= ${sqlStartDate}
         AND item_date <= ${sqlEndDate}
         ORDER BY item_date`;

      return { success: true, rows: rows };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "getUserItemsByDatesAsync" };
   }
};

export async function getUserWeightByDateAsync(userId, selectedDate) {
   const sqlDate = selectedDate.format('YYYY-MM-DD');

   try {
      const { rows } = await pool.sql`SELECT weight_date, weight_pounds FROM public.weights
	      WHERE user_id = ${userId}
         AND weight_date = ${sqlDate}`;

      return { success: true, rows: rows };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "getUserWeightByDateAsync" };
   }
};

export async function insertUserWeightAsync(userId, selectedDate, weight_pounds) {
   const sqlDate = selectedDate.format('YYYY-MM-DD');

   // Note the ON CONFLICT only works here because we already set a combo primary key on the table.
   try {
      await pool.sql`INSERT INTO public.weights
      (user_id, weight_date, weight_pounds)
      VALUES
      (
         ${userId},
         ${sqlDate},
         ${weight_pounds}
      )
      ON CONFLICT (user_id, weight_date)
      DO UPDATE SET weight_pounds = ${weight_pounds}`;

      return { success: true, };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "insertUserWeightAsync" };
   }
}

export async function getUserWeightsByDatesAsync(userId, { startDate, endDate }) {
   const sqlStartDate = startDate.format('YYYY-MM-DD');
   const sqlEndDate = endDate.format('YYYY-MM-DD');

   try {
      const { rows } = await pool.sql`SELECT weight_date, weight_pounds FROM public.weights
	      WHERE user_id = ${userId}
         AND weight_date >= ${sqlStartDate}
         AND weight_date <= ${sqlEndDate}
         ORDER BY weight_date`;

      return { success: true, rows: rows };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "getUserWeightsByDatesAsync" };
   }
};

export async function insertUserItemAsync(userId, newRow) {
   const { item_calories, item_count, item_description, item_date } = newRow;
   const sqlItemDate = dayjs(item_date).format('YYYY-MM-DD');
   const sqlLastUpdateDate = dayjs().utc().format();

	try {
      const { rows } = await pool.sql`INSERT INTO public.items
      (user_id, item_calories, item_count, item_description, item_date, last_update_date)
      VALUES
      (
         ${userId},
         ${item_calories},
         ${item_count},
         ${item_description.trim()},
         ${sqlItemDate},
         ${sqlLastUpdateDate}
      )
      RETURNING item_id`;

      return { success: true, newItemId: rows[0].item_id };
	} catch (e) {
      return { success: false, errorMessage: e.message, method: "insertUserItemAsync" };
	}
}

export async function deleteUserItemAsync(userId, item_id) {
   // userId is redundant here, but we submit it anyway for extra-ness.
	try {
      await pool.sql`DELETE FROM public.items
         WHERE item_id = ${item_id}
         AND user_id = ${userId}`;
      return { success: true };
	} catch (e) {
      return { success: false, errorMessage: e.message, method: "deleteUserItemAsync" };
	}
};

export async function updateUserItemAsync(userId, updatedRow) {
   // userId is redundant here, but we submit it anyway for extra-ness.
   const { id, item_calories, item_count, item_description } = updatedRow;
   const sqlLastUpdateDate = dayjs().utc().format();

   try {
      await pool.sql`UPDATE public.items
         SET
            item_calories = ${item_calories},
            item_count = ${item_count},
            item_description = ${item_description},
            last_update_date = ${sqlLastUpdateDate}
         WHERE item_id = ${id}
         AND user_id = ${userId}`;
      return { success: true };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "updateUserItemAsync" };
   }
};

export async function updateUserOptionsAsync(userId, columnName, newValue) {
   try {
      let result;
      // Vercel createPool doesn't allow dynamic column names, so if we want to make this dynamic we need to use pg instead.
      switch (columnName) {
         case "user_goal":
            result = await pool.sql`UPDATE public.users SET user_goal = ${newValue} WHERE user_id = ${userId} RETURNING *`;
            break;
         case "user_age":
            result = await pool.sql`UPDATE public.users SET user_age = ${newValue} WHERE user_id = ${userId} RETURNING *`;
            break;
         case "user_weight":
            result = await pool.sql`UPDATE public.users SET user_weight = ${newValue} WHERE user_id = ${userId} RETURNING *`;
            break;
         case "user_height":
            result = await pool.sql`UPDATE public.users SET user_height = ${newValue} WHERE user_id = ${userId} RETURNING *`;
            break;
         case "user_gender":
            result = await pool.sql`UPDATE public.users SET user_gender = ${newValue} WHERE user_id = ${userId} RETURNING *`;
            break;
         default:
            break;
		}

      return { success: true, userRecord: result.rows[0], };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "updateUserOptionsAsync" };
   }
};

export async function adminGetUsersAndDateTotals() {
   try {
      const usersResult = await pool.sql`SELECT
         user_id AS id, timezone, auth0_email, auth0_email_verified, auth0_updated_at, user_goal
         FROM public.users`;

      const itemsResult = await pool.sql`SELECT user_id, item_date AS itemsdate, SUM(item_calories * item_count) AS datetotal
	      FROM public.items
	      GROUP BY user_id, item_date`;

      const data = usersResult.rows.map(r => ({
         ...r,
         dateTotals: itemsResult.rows
            .filter(i => i.user_id == r.user_id)
            .map(i => ({ itemsDate: new Date(i.itemsdate), dateTotal: i.datetotal, }))
            .sort((a, b) => a.itemsDate.getTime() - b.itemsDate.getTime()),
      }));

      return { success: true, data: data };
   } catch (e) {
      return { success: false, errorMessage: e.message, method: "adminGetUsersAndDateTotals" };
   }
};
