import React, { useState, useEffect } from "react";
import { getUserItemsByDatesAsync } from '../services/postgres-pool';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import * as dayjs from 'dayjs';
import { DatabaseError } from "../components/database-error";

const green = "#0F9D58";
const yellow = "#F4B400";
const red = "#DB4437";
const rangeOptions = [
   { id: 0, text: "1 Week" },
   { id: 1, text: "2 Weeks" },
   { id: 2, text: "1 Month" },
   { id: 3, text: "3 Months" },
   { id: 4, text: "6 Months" },
];

function mapRows(databaseArray) {
   let mappedRows = [];

   databaseArray.reduce(function (result, row) {
      const rowDate = dayjs(row.item_date).format('YYYY-MM-DD');

      if (!result[rowDate]) {
         result[rowDate] = { groupDate: rowDate, groupTotal: 0 };
         mappedRows.push(result[rowDate])
      }
      result[rowDate].groupTotal += row.item_calories * row.item_count;
      return result;
   });

   // SQL is responsible for sorting.
   return mappedRows;
};

function getXAxisLabel(labelDate) {
   // Only return label if it's exactly midnight.
   const obj = dayjs(labelDate);
   return obj.get('hour') == 0 ? obj.format("M/D") : "";
}

export const History = ({ userGoal, userId }) => {
   const yellowThreshold = userGoal + 1;
   const redThreshold = userGoal + userGoal / 3;

   const [data, setData] = useState([]);
   const [dateRange, setDateRange] = useState(
      {
         startDate: dayjs().startOf("day").subtract(7, 'day'),
         endDate: dayjs().startOf("day").subtract(1, 'day'),
      });
   const [rangeOption, setRangeOption] = useState(0);
   const [databaseResult, setDatabaseResult] = useState({});

   useEffect(() => {
      getUserItemsByDatesAsync(userId, dateRange)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setData(mapRows(result.rows));
            }
         });
    }, []);

   const handleDateRangeChange = (rangeOptionId) => {
      const todaysDate = dayjs().startOf("day");

      let startDate;
      switch (rangeOptionId) {
         case 1:
            startDate = todaysDate.subtract(14, 'day');
            break;
         case 2:
            startDate = todaysDate.subtract(1, 'month');
            break;
         case 3:
            startDate = todaysDate.subtract(3, 'month');
            break;
         case 4:
            startDate = todaysDate.subtract(6, 'month');
            break;
         default:
            startDate = todaysDate.subtract(7, 'day');
            break;
      }

      const updatedDateRange = {
         startDate: startDate,
         endDate: todaysDate.subtract(1, 'day'),
      };

      getUserItemsByDatesAsync(userId, updatedDateRange)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setRangeOption(rangeOptionId);
               setDateRange(updatedDateRange);
               setData(mapRows(result.rows));
            }
         });
   }

	return (
		<Paper>
			<Card>
            <CardHeader
               title="History"
               subheader={
                  <div>
                     {dateRange.startDate.format('ddd, MMMM D, YYYY')}
                     - {dateRange.endDate.format('ddd, MMMM D, YYYY')}
                  </div>
               }
               action={
                  <TextField
                     select
                     sx={{ minWidth: 150 }}
                     label="Date Range"
                     value={rangeOption}
                     onChange={(event) => handleDateRangeChange(event.target.value)}
                  >
                     {rangeOptions.map(r =>
                        <MenuItem key={r.id} value={r.id}>{r.text}</MenuItem>
                     )}
                  </TextField>
               }
            />
				<CardContent>
					<Grid container justifyContent="center">
                  <Grid item width="100%">
                     <DatabaseError databaseResult={databaseResult} />

                     {!databaseResult.errorMessage &&
                        (
                        <LineChart
                           margin={{ top: 10, }}
                           height={300}
                           sx={{ width: '100%' }}
                           grid={{ horizontal: true }}
                           series={[
                              {
                                 data: data.map(g => g.groupTotal),
                                 valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
                              },
                           ]}
                           yAxis={[
                              {
                                 min: 0,
                                 colorMap:
                                 {
                                    type: 'piecewise',
                                    thresholds: [yellowThreshold, redThreshold],
                                    colors: [green, yellow, red],
                                 },
                              },
                           ]}
                           xAxis={[
                              {
                                 scaleType: 'time',
                                 min: dateRange.startDate.toDate(),
                                 max: dateRange.endDate.toDate(),
                                 data: data.map(g => dayjs(g.groupDate).toDate()),
                                 valueFormatter: (value) => getXAxisLabel(value),
                              },
                           ]}>
                           <ChartsReferenceLine y={userGoal} lineStyle={{ strokeDasharray: "10 5", strokeWidth: "1" }} />
                        </LineChart>
                        )}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Paper >
	);
};
