import React, { useState, useEffect } from "react";
import {
   deleteUserItemAsync,
   getUserItemsAsync,
   insertUserItemAsync,
   updateUserItemAsync,
} from '../services/postgres-pool';
import { DailyGoal } from './daily-goal';
import { History } from './history';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
   GridRowModes,
   DataGrid,
   GridToolbarContainer,
   GridActionsCellItem,
   GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { DatePicker } from '@mui/x-date-pickers';
import { DatabaseError } from "../components/database-error";
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

function mapRows(databaseArray) {
   // DataGrid requires an id column named id.
   return databaseArray.map(row => ({
      id: row.item_id,
      item_calories: row.item_calories,
      item_count: row.item_count,
      item_date: row.item_date,
      item_description: row.item_description,
      isNew: false,
   }));
};

function EditToolbar(props) {
   const { setRows, setRowModesModel, datePicked } = props;

   const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, {
         id,
         item_calories: '',
         item_count: 1,
         item_description: '',
         item_date: datePicked.toDate(),
         isNew: true
      }]);
      setRowModesModel((oldModel) => ({
         ...oldModel,
         [id]: { mode: GridRowModes.Edit, fieldToFocus: 'item_calories' },
      }));
   };

   return (
      <GridToolbarContainer>
         <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add item
         </Button>
      </GridToolbarContainer>
   );
}

export const EnterCalories = ({ userRecord }) => {
   const [datePicked, setDatePicked] = useState(dayjs());
   const [rows, setRows] = useState([]);
   const [rowModesModel, setRowModesModel] = useState({});
   const [allRowsValid, setAllRowsValid] = useState(true);
   const [databaseResult, setDatabaseResult] = useState({});

   useEffect(() => {
      getUserItemsAsync(userRecord.user_id, datePicked)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setRows(mapRows(result.rows))
            }
         });
   }, []);

   const changeDate = (newDate) => {
      getUserItemsAsync(userRecord.user_id, newDate)
         .then(result => {
            setDatabaseResult(result);
            if (result.success) {
               setRows(mapRows(result.rows));
               setDatePicked(newDate);
				}
         });
   };

   const handleRowEditStop = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
         event.defaultMuiPrevented = true;
      }
   };

   const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
   };

   const handleSaveClick = (id) => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
   };

   const handleDeleteClick = (id) => () => {
      deleteUserItemAsync(userRecord.user_id, id)
         .then(result => {
            setDatabaseResult(result);
				if (result.success) {
               setRows(rows.filter((row) => row.id !== id));
            }
            
            return result;
         })
         .then(result => {
            if (result.success) {
               setRows(rows.filter((row) => row.id !== id));

               const newRowModesModel = { ...rowModesModel };
               delete newRowModesModel[id];
               setRowModesModel(newRowModesModel);
				}
         });
   };

   const handleCancelClick = (id) => () => {
      setDatabaseResult({});

      setAllRowsValid(true);

      setRowModesModel({
         ...rowModesModel,
         [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
         setRows(rows.filter((row) => row.id !== id));
      }
   };

   const processRowUpdate = (newRow) => {
      const isNewRowValid = newRow.item_calories !== ""
         && newRow.item_count !== ""
         && newRow.item_description !== null
         && newRow.item_description !== "";

      if (!isNewRowValid) {
         setAllRowsValid(false);
         setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } });
         return newRow;
      }

      setAllRowsValid(true);

      // Trim all descriptions.
      newRow["item_description"] = newRow["item_description"].trim();

      if (newRow.isNew) {
         insertUserItemAsync(userRecord.user_id, newRow)
            .then(result => {
               setDatabaseResult(result);
               if (result.success) {
                  const oldRowId = newRow.id;

                  const updatedRowModesModel = { ...rowModesModel };
                  delete updatedRowModesModel[oldRowId];

                  newRow.id = result.newItemId;
                  newRow.isNew = false;

                  const rowToRemove = rows.find(r => r.id == oldRowId);
                  const index = rows.indexOf(rowToRemove);
                  let updatedRows = [...rows];
                  updatedRows.splice(index, 1);
                  updatedRows = [...updatedRows, newRow];
                  return { updatedRowModesModel, updatedRows };
               }
            })
            .then(({ updatedRowModesModel, updatedRows }) => {
               setRowModesModel(updatedRowModesModel);
               setRows(updatedRows);
            })
            .then(() => {
               return newRow;
            });
      }
      else {
         updateUserItemAsync(userRecord.user_id, newRow)
            .then(result => {
               setDatabaseResult(result);
               const rowToSwap = rows.find(r => r.id == newRow.id);
               const index = rows.indexOf(rowToSwap);
               let updatedRows = [...rows];
               updatedRows[index] = newRow;
               return updatedRows;
            })
            .then((updatedRows) => {
               setRows(updatedRows);
            })
            .then(() => {
               return newRow;
            });
      }
      
      return newRow;
   };

   const handleRowModesModelChange = () => {
      // Clean up all items in rowModesModel that are no longer in rows.
      const newRowModesModel = { ...rowModesModel };

      for (const [key, _] of Object.entries(rowModesModel)) {
         const correlatingRow = rows.find(r => r.id == key);

         if (!correlatingRow) {
            delete newRowModesModel[key];
			}
      }

      setRowModesModel(newRowModesModel);
   };
   
   const columns = [
      {
         field: 'item_calories',
         headerName: 'Calories',
         type: 'number',
         align: 'left',
         headerAlign: 'left',
         flex: 1,
         maxWidth: 80,
         editable: true,
         disableColumnMenu: true,
      },
      {
         field: 'item_count',
         headerName: 'Count',
         type: 'number',
         align: 'left',
         headerAlign: 'left',
         flex: 1,
         maxWidth: 80,
         editable: true,
         disableColumnMenu: true,
      },
      {
         field: 'item_description',
         headerName: 'Description',
         flex: 2,
         editable: true,
         disableColumnMenu: true,
      },
      {
         field: 'item_date',
         headerName: 'Date',
         type: 'date',
         disableColumnMenu: true,
      },
      {
         field: 'actions',
         type: 'actions',
         headerName: 'Actions',
         cellClassName: 'actions',
         flex: 1,
         minWidth: 80,
         getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
               return [
                  <GridActionsCellItem
                     icon={
                        <Box display="flex">
                           <SaveIcon />
                           <Typography ml={0.5} sx={{ display: { xs: "none", md: "inline-block" } }}>Save</Typography>
                        </Box>
                     }
                     label="Save"
                     sx={{
                        color: 'primary.main',
                     }}
                     onClick={() => handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                     icon={
                        <Box display="flex">
                           <CancelIcon />
                           <Typography ml={0.5} sx={{ display: { xs: "none", md: "inline-block" } }}>Cancel</Typography>
                        </Box>
                     }
                     label="Cancel"
                     className="textPrimary"
                     onClick={handleCancelClick(id)}
                     color="inherit"
                  />,
               ];
            }

            return [
               <GridActionsCellItem
                  icon={
                     <Box display="flex">
                        <EditIcon />
                        <Typography ml={0.5} sx={{ display: { xs: "none", md: "inline-block" } }}>Edit</Typography>
                     </Box>
                  }
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
               />,
               <GridActionsCellItem
                  icon={
                     <Box display="flex">
                        <DeleteIcon />
                        <Typography ml={0.5} sx={{ display: { xs: "none", md: "inline-block" } }}>Delete</Typography>
                     </Box>
                  }
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
               />,
            ];
         },
      },
   ];

   return (
      <div>
         {process.env.REACT_APP_DEBUG_MODE && process.env.REACT_APP_DEBUG_MODE == "true" &&
            <div>
               <div>{JSON.stringify(rowModesModel)}</div>
               <div>{JSON.stringify(rows)}</div>
            </div>
         }

         <Grid container justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={2}>
               <Paper sx={{ display: "inline-block" }}>
                  <DatePicker
                     id="datePicked"
                     label="Date"
                     value={datePicked}
                     onChange={(newDate) => changeDate(newDate)}
                  />
               </Paper>
            </Grid>
            <Grid item xs={12} md={10}>
               <Stack spacing={2}>
                  {!allRowsValid &&
                     <Alert m={2} severity="error">Please enter values for all columns in new rows before saving.</Alert>}

                  <DatabaseError databaseResult={databaseResult} />

                  <Paper>
                     <DataGrid
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        slots={{
                           toolbar: EditToolbar,
                           noRowsOverlay: () => { (<div />) },
                        }}
                        slotProps={{
                           toolbar: { setRows, setRowModesModel, datePicked },
                        }}
                        autoHeight={true}
                        disableColumnSorting
                        initialState={{
                           columns: {
                              columnVisibilityModel: {
                                 item_date: false,
                              },
                           },
                        }}
                     />
                  </Paper>
               </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
               <DailyGoal datePicked={datePicked} rows={rows} userGoal={userRecord.user_goal} />
            </Grid>
            <Grid item xs={12} md={6}>
               <History userGoal={userRecord.user_goal} userId={userRecord.user_id} />
            </Grid>
         </Grid>
         {/*
         // TODO:: make a universal cleanup based on what's in handle delete click
         */}
      </div>
   );
};
